<template>
  <b-row>
    <b-col cols="12">
      <order-newness-table />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import OrderNewnessTable from './OrderNewnessTable.vue'

export default {
  components: {
    BRow,
    BCol,

    OrderNewnessTable
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>